































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import jwlProgrammeApplicant from './jwlProgrammeApplicant.vue';
import { ProgrammeApplicant, ProgrammeLearningGroup } from '@/store/interfaces/Programme';

@Component({
  components: {
    jwlProgrammeApplicant,
  },
})
export default class SisProgrammeLearningGroup extends Vue {
  @Prop(Object)
  learningGroup!: ProgrammeLearningGroup;

  open = false;
  applicants: ProgrammeApplicant[] = [];

  toggleOpen (): void {
    this.open = !this.open;
  }

  updateStudent (data: ProgrammeApplicant): void {
    const student = this.applicants.find((applicant) => applicant.id === data.id);
    if (student) {
      student.status = data.status;
    }
  }

  get stats (): Record<string, number> {
    const accepted = this.applicants
      ? this.applicants.filter((applicant) => applicant.status !== 'pending' && applicant.status !== 'declined')
      : [];
    const active = this.applicants
      ? this.applicants.filter((applicant) => applicant && applicant.status === 'active')
      : [];

    return {
      current: this.applicants ? this.applicants.length : 0,
      accepted: accepted.length,
      active: active.length,
    };
  }

  get openClass (): Record<string, boolean> {
    return {
      'jwl-programme-clc__toggle--open': this.open,
    };
  }

  mounted (): void {
    this.applicants = this.learningGroup.applicants;
  }
}
