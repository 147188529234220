


































































































import { mapGetters } from 'vuex';
import Vue from 'vue';
import Component from 'vue-class-component';
import { UserInterface } from 'common-modules/src/store/interface/User';
import { TranslateResult } from 'vue-i18n';
import BackBar from '@/components/BackBar.vue';
import SisProgrammeStatusBar from '@/views/programme/components/SisProgrammeStatusBar.vue';
import jwlProgrammeAddStudent from '../../helper/jwlProgrammeAddStudent.vue';
import SisProgrammeStatusActions from './components/SisProgrammeStatusActions.vue';
import SisProgrammeBreaks from './components/SisProgrammeBreaks.vue';
import { Programme } from '@/store/interfaces/Programme';
import SisProgrammeLearningGroup from '@/views/programme/components/SisProgrammeLearningGroup.vue';

const IconArrowLeft = () => import('common-modules/src/assets/fa-icons/solid/arrow-left.svg');
const IconArrowRight = () => import('common-modules/src/assets/fa-icons/solid/arrow-right.svg');
const CommonError = () => import('common-modules/src/components/CommonError.vue');
const JwlButton = () => import('@/components/JwlButton.vue');

@Component({
  components: {
    BackBar,
    CommonError,
    IconArrowLeft,
    IconArrowRight,
    JwlButton,
    jwlProgrammeAddStudent,
    SisProgrammeBreaks,
    SisProgrammeLearningGroup,
    SisProgrammeStatusActions,
    SisProgrammeStatusBar,
  },
  computed: mapGetters([
    'currentUser',
  ]),
})
export default class SisProgrammeOverview extends Vue {
  currentUser!: UserInterface;

  loaded = false;
  error = null;
  jwlIdToAdd = '';
  programme: Programme|null = null;

  fetchData (): void {
    this.loaded = false;
    this.$store.dispatch('getData', `programme/data/${this.$route.params.programme}`)
      .then((data) => {
        this.programme = data;
        this.loaded = true;
      })
      .catch((e) => {
        this.error = e;
      });
  }

  formattedDate (dateString: string|number): string {
    let date;
    if (typeof dateString === 'number') {
      date = new Date(dateString * 1000);
    } else {
      date = new Date(dateString);
    }
    return date.toLocaleDateString();
  }

  formattedTime (dateString: string|number): string {
    let date;
    if (typeof dateString === 'number') {
      date = new Date(dateString * 1000);
    } else {
      date = new Date(dateString);
    }
    return date.toLocaleTimeString();
  }

  addStudent (data: any): void {
    const programmeClc = this.programme?.learningGroups.find((clc) => clc.id === data.clcId);
    if (programmeClc) {
      if (!programmeClc.applicants) {
        programmeClc.applicants = [];
      }
      programmeClc.applicants.push(data.applicant);
    }
  }

  get pastEndDate (): boolean {
    if (this.programme?.endDate) {
      const now = new Date();
      const end = new Date(this.programme.endDate);
      return now >= end;
    }
    return false;
  }

  get applicantsTranslation (): TranslateResult|string {
    let applicants = 0;
    let female = 0;
    let male = 0;
    let other = 0;
    if (this.programme?.learningGroups) {
      this.programme.learningGroups.forEach((clc) => {
        if (clc.applicants) {
          applicants += clc.applicants.length;

          clc.applicants.forEach((applicant) => {
            if (applicant.gender === 'male') {
              male += 1;
            } else if (applicant.gender === 'female') {
              female += 1;
            } else if (applicant.gender === 'other') {
              other += 1;
            }
          });
        }
      });
    }
    const clcs = this.programme?.learningGroups.length || 0;
    const applicantTrans = this.$tc('programmeManagement.applicants', applicants);
    const clcTrans = this.$tc('programmeManagement.applicantLearningGroups', clcs);
    return this.$t('programmeManagement.applicantsFromLearningGroups', {
      applicants: applicantTrans,
      learningGroups: clcTrans,
      male,
      female,
      other,
    });
  }

  get enrolledTranslation (): string {
    const withdrawn = {
      total: 0,
      male: 0,
      female: 0,
    };
    const declined = {
      total: 0,
      male: 0,
      female: 0,
    };
    const active = {
      total: 0,
      male: 0,
      female: 0,
    };
    const failed = {
      total: 0,
      male: 0,
      female: 0,
    };

    if (this.programme?.learningGroups) {
      this.programme.learningGroups.forEach((learningGroup) => {
        if (learningGroup.applicants) {
          learningGroup.applicants.forEach((applicant) => {
            if (['active', 'completed'].includes(applicant.status)) {
              active.total += 1;

              if (applicant.gender === 'male') {
                active.male += 1;
              } else if (applicant.gender === 'female') {
                active.female += 1;
              }
            } else if (applicant.status === 'withdrawn') {
              withdrawn.total += 1;

              if (applicant.gender === 'male') {
                withdrawn.male += 1;
              } else if (applicant.gender === 'female') {
                withdrawn.female += 1;
              }
            } else if (applicant.status === 'declined') {
              declined.total += 1;

              if (applicant.gender === 'male') {
                declined.male += 1;
              } else if (applicant.gender === 'female') {
                declined.female += 1;
              }
            } else if (applicant.status === 'failed') {
              failed.total += 1;

              if (applicant.gender === 'male') {
                failed.male += 1;
              } else if (applicant.gender === 'female') {
                failed.female += 1;
              }
            }
          });
        }
      });
    }

    let trans = '';

    if (this.programme?.status === 'running') {
      trans = ` ${active.total} active (${active.male} male, ${active.female} female), ${withdrawn.total} withdrawn (${withdrawn.male} male, ${withdrawn.female} female).`;
    } else if (this.programme && ['applying', 'preparing', 'prep_done'].includes(this.programme.status)) {
      trans = ` ${active.total} accepted (${active.male} male, ${active.female} female), ${declined.total} declined (${declined.male} male, ${declined.female} female).`;
    } else if (this.programme?.status === 'finished') {
      trans = ` ${active.total} completed (${active.male} male, ${active.female} female), ${failed.total} failed (${failed.male} male, ${failed.female} female).`;
    }

    return trans;
  }

  get classroomDistributionTranslation (): TranslateResult|null {
    if (!this.programme) {
      return null;
    }

    const allowedStates = ['new', 'applying', 'preparing'];
    if (allowedStates.includes(this.programme.status)) {
      return this.$tc('programmeManagement.classroomsPossible', this.programme.classroomsPossible);
    }
    const { hasGlobal, hasLocal } = this.programme;
    const classrooms: number[] = [];
    if (hasGlobal) {
      this.programme.learningGroups.forEach((clc) => {
        if (clc.applicants) {
          clc.applicants.forEach((applicant) => {
            if (applicant.onlineClassroom && !classrooms.includes(applicant.onlineClassroom)) {
              classrooms.push(applicant.onlineClassroom);
            }
          });
        }
      });
      return this.$tc('programmeManagement.distributedInClassrooms', classrooms.length);
    }
    if (hasLocal) {
      this.programme.learningGroups.forEach((clc) => {
        if (clc.applicants) {
          clc.applicants.forEach((applicant) => {
            if (applicant.onsiteClassroom && !classrooms.includes(applicant.onsiteClassroom)) {
              classrooms.push(applicant.onsiteClassroom);
            }
          });
        }
      });
      return this.$tc('programmeManagement.distributedInOnsiteClassrooms', classrooms.length);
    }
    return this.$t('programmeManagement.neitherGlobalNorLocal');
  }

  get editClassAllowed (): boolean {
    if (this.programme) {
      const { status } = this.programme;
      const forbiddenStates = ['new', 'applying', 'finished'];
      return !forbiddenStates.includes(status);
    }

    return false;
  }

  get programmeConcentrationNames (): TranslateResult|string {
    if (this.programme?.concentration) {
      return this.programme.concentration.label;
    }

    return this.$t('programmeManagement.concentrationUnknown');
  }

  mounted (): void {
    this.fetchData();
  }
}
