


















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import JwlSubmitRow from '@/components/JwlSubmitRow.vue';
import { ProgrammeBreak } from '@/store/interfaces/Programme';

const JwlButton = () => import('@/components/JwlButton.vue');
const CommonFormElement = () => import('common-modules/src/components/CommonFormElement.vue');

@Component({
  components: {
    JwlSubmitRow,
    JwlButton,
    CommonFormElement,
  },
})
export default class SisProgrammeBreak extends Vue {
  $refs!: {
    breakForm: HTMLFormElement;
  }

  @Prop(String)
  programmeKey!: string;

  @Prop(Array)
  programmeBreak!: ProgrammeBreak|undefined;

  editBreak = false;
  breakDeleting = 0;
  breakStart = new Date().toString();
  breakDuration = 1;
  formSubmitStatus = 0;
  submitError = null;

  deleteBreak (): void {
    const { programmeBreak } = this;
    if (this.breakDeleting !== 1 && programmeBreak) {
      this.breakDeleting = 1;
      const deleteForm = new FormData();
      deleteForm.set('delete-index', programmeBreak.index.toString());
      this.$store.dispatch('postData', {
        url: `programme/break-delete/${this.programmeKey}`,
        formData: deleteForm,
      }).then((data) => {
        this.submitError = null;
        this.breakDeleting = 2;
        setTimeout(() => {
          this.$store.commit('UPDATE_PROGRAMME', data);
        }, 2500);
      }).catch((e) => {
        this.formSubmitStatus = -1;
        this.submitError = e;
        this.breakDeleting = -1;
      });
    }
  }

  submitForm (): void {
    const { breakForm } = this.$refs;

    if (breakForm.reportValidity() && this.formSubmitStatus !== 1) {
      const formData = new FormData(breakForm);
      const { programmeBreak } = this;
      if (programmeBreak && programmeBreak.index >= 0) {
        formData.set('index', programmeBreak.index.toString());
      }

      this.formSubmitStatus = 1;
      this.$store.dispatch('postData', {
        url: `programme/breaks/${this.programmeKey}`,
        formData,
      }).then((data) => {
        this.$store.commit('UPDATE_PROGRAMME', data);
        this.submitError = null;
        this.formSubmitStatus = 2;
        setTimeout(() => {
          this.formSubmitStatus = 0;
        }, 2500);
      }).catch((e) => {
        this.formSubmitStatus = -1;
        this.submitError = e;
      });
    } else {
      this.formSubmitStatus = 3;
    }
  }

  get breakDurationEntry (): TranslateResult|string {
    const { programmeBreak } = this;
    if (programmeBreak) {
      const date = new Date(programmeBreak.startDate);
      const duration = this.$tc(`programmeManagement.breakDuration.${programmeBreak.unit}`, programmeBreak.duration);
      return this.$t('programmeManagement.breakEntry', {
        startDate: date.toLocaleDateString(),
        duration,
      });
    }

    return '';
  }

  mounted (): void {
    if (this.programmeBreak) {
      this.breakStart = this.programmeBreak.startDate;
      this.breakDuration = this.programmeBreak.duration;
    } else {
      this.editBreak = true;
    }
  }
}
