<template>
  <div class="jwl-applicant-actions">
    <div class="jwl-applicant-actions__form" v-if="submittingStatus === 0">
      <jwl-button class="jwl-applicant-actions__submit jwl-applicant-actions__submit--decline"
        margin-right
        @button-clicked="updateStatus('declined')"
        v-if="showDeclineButton">
        {{$t('programmeManagement.declineStudent')}}
      </jwl-button>
      <jwl-button class="jwl-applicant-actions__submit jwl-applicant-actions__submit--accept"
        margin-right
        @button-clicked="updateStatus('active')"
        v-if="allowAccept">
        {{$t('programmeManagement.acceptStudent')}}
      </jwl-button>
      <jwl-button class="jwl-applicant-actions__submit jwl-applicant-actions__submit--withdraw"
        margin-right
        @button-clicked="updateStatus('withdrawn')"
        v-if="showWithdrawButton">
        {{$t('programmeManagement.withdrawStudent')}}
      </jwl-button>
      <jwl-button class="jwl-applicant-actions__submit jwl-applicant-actions__submit--reactivate"
        margin-right
        @button-clicked="updateStatus('active')"
        v-if="showReactivateButton">
        {{$t('programmeManagement.reactivateStudent')}}
      </jwl-button>
    </div>

    <div class="jwl-applicant-actions__submitting" v-if="submittingStatus === 1" v-t="'syncStatus.submitting'" />

    <div class="jwl-applicant-actions__submitted" v-if="submittingStatus === 2" v-t="'syncStatus.submitted'" />

    <div class="jwl-applicant-actions__submit-error" v-if="submittingStatus === -1">
      {{error.trans ? $t(error.message) : error.message}}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

const JwlButton = () => import('@/components/JwlButton.vue');

const STATUS_NOTHING = 0;
const STATUS_SUBMITTING = 1;
const STATUS_SUCCESS = 2;
const STATUS_ERROR = -1;

export default {
  props: {
    programmeApplicant: Object,
    submittingStatus: Number,
  },
  components: {
    JwlButton,
  },
  data () {
    return {
      error: null,
    };
  },
  methods: {
    updateStatus (targetStatus) {
      if (this.submittingStatus !== STATUS_SUBMITTING) {
        this.$emit('update-sync-status', STATUS_SUBMITTING);
        const FD = new FormData();

        FD.append('performingAction', 'updateStatus');
        FD.append('targetStatus', targetStatus);
        FD.append('applicantId', this.programmeApplicant.id.toString());

        this.$store.dispatch('postData', {
          url: `programme/update-student/${this.$route.params.programme}`,
          formData: FD,
        }).then((data) => {
          this.$emit('update-student', {
            id: this.programmeApplicant.id,
            status: data.status,
          });
          this.$emit('update-sync-status', STATUS_SUCCESS);
          setTimeout(() => {
            this.$emit('update-sync-status', STATUS_NOTHING);
          }, 2500);
        }).catch((e) => {
          this.error = e;
          this.$emit('update-sync-status', STATUS_ERROR);
        });
      }
    },
  },
  computed: {
    allowAccept () {
      const { status } = this.programmeApplicant;
      const { role } = this.currentUser;

      if (role === 'coordinator') {
        return false;
      }

      return !status
        || status === 'pending'
        || status === 'declined';
    },
    showDeclineButton () {
      const { status } = this.programmeApplicant;
      const { role } = this.currentUser;

      if (role === 'coordinator') {
        return false;
      }

      return (status === 'pending'
        || status === 'withdrawn'
        || !status
        || (status === 'active'
          && !(this.programmeApplicant.onlineClassroom
            || this.programmeApplicant.onsiteClassroom)));
    },
    showWithdrawButton () {
      const { status } = this.programmeApplicant;
      return status === 'active'
        && (this.programmeApplicant.onlineClassroom
          || this.programmeApplicant.onsiteClassroom);
    },
    showReactivateButton () {
      const { status, onlineClassroom, onsiteClassroom } = this.programmeApplicant;
      const hideInStatus = ['active', 'pending'];
      return !hideInStatus.includes(status)
        && (onlineClassroom || onsiteClassroom);
    },
    ...mapGetters([
      'currentUser',
    ]),
  },
};
</script>

<style lang="sass">
@use "~common-modules/src/styles/variables"

.jwl-applicant-actions
  align-items: baseline
  display: flex
  justify-content: flex-end

  &__form
    display: flex
    flex: 0 1 35rem
    justify-content: flex-end

    .form-element
      margin-right: .25rem

  &__submit
    --button-color: var(--menu-color)

    &--accept,
    &--reactivate
      --button-color: var(--color-success)

    &--decline,
    &--withdraw
      --button-color: var(--color-error)

  &__submitting
    color: var(--color-primary)
    padding: .5rem 1.5rem

  &__submitted
    background-color: variables.$success
    border-radius: variables.$border-radius
    color: variables.$white
    padding: .25rem 1.5rem

  &__submit-error
    background-color: variables.$error
    border-radius: variables.$border-radius
    color: variables.$white
    padding: .25rem 1.5rem

</style>
