<template lang="pug">
  .jwl-programme-add-student
    input.jwl-programme-add-student__input(
      type="text"
      :placeholder="$t('programmeManagement.enterEmail')"
      v-model="jwlIdToAdd"
      @keypress.enter="addStudent")
    .jwl-programme-add-student__button(@click="addStudent" :class="submitStatusClass")
      icon-plus(v-if="submitStatus === 0")
      icon-spinner-third(v-if="submitStatus === 1")
      icon-check(v-if="submitStatus === 2")
      icon-exclamation-triangle(v-if="submitStatus === -1")
    .jwl-programme-add-student__success(v-if="submitStatus === 2")
      | Successfully added {{lastAdded}}.
    .jwl-programme-add-student__error(v-if="error")
      | {{error.trans ? $t(error.message) : error.message}}
</template>

<script>
const STATUS_NOTHING = 0;
const STATUS_SUBMITTING = 1;
const STATUS_SUCCESS = 2;
const STATUS_ERROR = -1;

const IconCheck = () => import('common-modules/src/assets/fa-icons/regular/check.svg');
const IconPlus = () => import('common-modules/src/assets/fa-icons/regular/plus.svg');
const IconExclamationTriangle = () => import('common-modules/src/assets/fa-icons/duotone/exclamation-triangle.svg');
const IconSpinnerThird = () => import('common-modules/src/assets/fa-icons/duotone/spinner-third.svg');

export default {
  name: 'jwl-programme-add-student',
  props: {
    programmeId: [Number, String],
  },
  components: {
    IconCheck,
    IconPlus,
    IconExclamationTriangle,
    IconSpinnerThird,
  },
  data () {
    return {
      jwlIdToAdd: '',
      lastAdded: '',
      submitStatus: STATUS_NOTHING,
      error: null,
    };
  },
  methods: {
    addStudent () {
      if (this.submitStatus !== STATUS_SUBMITTING) {
        this.submitStatus = STATUS_SUBMITTING;
        this.error = null;
        const FD = new FormData();
        FD.append('email', this.jwlIdToAdd);

        const url = this.programmeId === 'gel'
          ? 'gel-management/add-student/gel'
          : `programme/add-student/${this.programmeId.toString()}`;

        this.$store.dispatch('postData', {
          url,
          formData: FD,
        }).then((data) => {
          this.$emit('student-added', data);
          this.lastAdded = this.jwlIdToAdd;
          this.jwlIdToAdd = '';
          this.submitStatus = STATUS_SUCCESS;

          setTimeout(() => {
            if (this.submitStatus === STATUS_SUCCESS) {
              this.submitStatus = STATUS_NOTHING;
            }
          }, 2000);
        }).catch((e) => {
          this.error = e;
          this.submitStatus = STATUS_ERROR;
        });
      }
    },
  },
  computed: {
    submitStatusClass () {
      return `jwl-programme-add-student__button--${this.submitStatus}`;
    },
  },
};
</script>

<style lang="sass">
@import "~common-modules/src/styles/variables"

.jwl-programme-add-student
  display: grid
  grid-template: "form button" 2.5rem "message message" auto / 10rem 2.5rem

  &__input
    align-items: center
    border: .08rem solid $grey
    border-radius: $border-radius 0 0 $border-radius
    border-right: transparent
    box-sizing: border-box
    font-size: 1rem
    grid-area: form
    height: 100%
    justify-content: flex-start
    padding: .25rem .75rem
    width: 100%

  &__button
    --border-color: var(--color-primary)
    --bg-color: var(--color-primary)
    --text-color: #{$white}

    align-items: center
    background-color: var(--bg-color)
    border: .1rem solid var(--border-color)
    border-radius: 0 $border-radius $border-radius 0
    box-sizing: border-box
    color: var(--text-color)
    cursor: pointer
    display: flex
    font-size: 1.5rem
    grid-area: button
    height: 100%
    justify-content: center
    transition: background-color .25s, color .25s
    width: 100%

    &:hover
      --bg-color: #{$white}
      --text-color: var(--color-primary)

    // Submitting
    &--1
      --bg-color: var(--color-primary)
      --border-color: var(--color-primary)

      &:hover
        --text-color: var(--color-primary)

    // Submitting
    &--2
      --bg-color: #{$success}
      --border-color: #{$success}

      &:hover
        --text-color: #{$success}

    // Error while submitting
    &---1
      --bg-color: #{$error}
      --border-color: #{$error}

      &:hover
        --text-color: #{$error}

  &__success,
  &__error
    background-color: $success
    border-radius: $border-radius
    color: $white
    grid-area: message
    margin-top: .25rem
    padding: .25rem .5rem

  &__error
    background-color: $error

</style>
