

























import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import Component from 'vue-class-component';
import { Programme } from '@/store/interfaces/Programme';

@Component
export default class SisProgrammeStatusBar extends Vue {
  @Prop(Object)
  sisProgramme!: Programme;

  activeSectionClass (checkValue: boolean): Record<string, boolean> {
    return {
      'sis-programme-status-bar__section--active': checkValue,
    };
  }

  get isOrHasPrepared (): boolean {
    const passedStates = ['preparing', 'prep_done', 'running', 'finished'];
    return passedStates.includes(this.sisProgramme.status);
  }

  get isActiveOrFinished (): boolean {
    const passedStates = ['running', 'finished'];
    return passedStates.includes(this.sisProgramme.status);
  }

  get prepText (): string {
    const { status } = this.sisProgramme;
    const prepDoneStates = ['prep_done', 'running', 'finished'];
    if (prepDoneStates.includes(status)) {
      return 'programmeManagement.prep_done';
    }
    if (status === 'preparing') {
      return 'programmeManagement.status_preparing';
    }
    return 'programmeManagement.pre-preparing';
  }

  get progress (): number {
    const preStartStates = ['new', 'applying', 'preparing', 'prep_done'];
    if (preStartStates.includes(this.sisProgramme.status)) {
      return 0;
    }
    const { startDate, endDate } = this.sisProgramme;
    if (this.sisProgramme.status === 'running' && startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const now = new Date();
      const progress = Math.min(100, Math.max(0, (((now.getTime() - start.getTime()) / (end.getTime() - start.getTime())) * 100)));
      return Math.round(progress);
    }
    return 100;
  }

  get progressStyle (): string {
    return `width: ${this.progress}%;`;
  }

  get runningText (): TranslateResult {
    const preStartStates = ['new', 'applying', 'preparing', 'prep_done'];
    if (preStartStates.includes(this.sisProgramme.status)) {
      return this.$t('programmeManagement.startPending');
    }
    if (this.sisProgramme.status === 'running') {
      return this.$t('programmeManagement.progressFinished', { progress: this.progress });
    }
    return this.$t('programmeManagement.status_finished');
  }
}
