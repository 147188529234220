<template>
  <div class="jwl-programme-applicant">
    <div class="jwl-programme-applicant__name">
      <span dir="auto" v-text="fullName" />
      <small dir="ltr" v-text="programmeApplicant.lmsId" />
    </div>

    <jwl-applicant-actions class="jwl-programme-applicant__header-action-row"
      :programme-applicant="programmeApplicant"
      :submitting-status="submittingStatus"
      :sync-error="error"
      @update-student="updateStudent"
      @update-sync-status="updateSyncStatus" />

    <div class="jwl-programme-applicant__status" :class="statusClass">
      <common-icon class="jwl-programme-applicant__status-icon" :icon-name="statusIcon" :icon-style="statusIconStyle" />
    </div>

    <div class="jwl-programme-applicant__toggle" :class="openClass" @click="toggleOpen" />

    <div class="jwl-programme-applicant__details" v-if="open">
      <sis-box-column
        :value-headline="$t('general.name')"
        :value-text="fullName"
        :value-muted="$t(`studentManagement.${programmeApplicant.gender}`)" />

      <sis-box-column
        :value-headline="$t('studentManagement.displayName')"
        :value-text="programmeApplicant.displayName" />

      <sis-box-column
        :value-headline="$t('studentManagement.birthDate')"
        :value-text="formattedDate(programmeApplicant.birthDate)"
        :value-muted="$t('studentManagement.age', { age: getAge(programmeApplicant.birthDate) })" />

      <sis-box-column
        :value-headline="$t('studentManagement.email')"
        :value-link-target="`mailto:${programmeApplicant.email}`"
        :value-text="programmeApplicant.email" />

      <sis-box-column
        :value-headline="$t('studentManagement.phone')"
        :value-link-target="`tel:${programmeApplicant.phone}`"
        :value-text="programmeApplicant.phone" />

      <form class="jwl-programme-applicant__form-section" ref="commentForm">
        <common-form-element field-name="comment" field-type="textarea" :value="comment" />

        <div class="jwl-programme-applicant__text-actions">
          <jwl-submit-row
            @on-click="updateComment"
            :form-submit-status="submitting"
            trans-key="formElement.updateComment">
            <template v-slot:error>
              {{error.trans ? $t(error.message) : error.message}}
            </template>
          </jwl-submit-row>

          <jwl-applicant-actions
            :programme-applicant="programmeApplicant"
            :submitting-status="submittingStatus"
            :sync-error="error"
            @update-student="updateStudent"
            @update-sync-status="updateSyncStatus" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import JwlSubmitRow from '@/components/JwlSubmitRow.vue';
import JwlApplicantActions from './jwlApplicantActions.vue';
import sisBoxColumn from '../../../helper/sisBoxColumn.vue';

const CommonFormElement = () => import('common-modules/src/components/CommonFormElement.vue');
const CommonIcon = () => import('common-modules/src/components/CommonIcon.vue');

const STATUS_NOTHING = 0;
const STATUS_SUBMITTING = 1;
const STATUS_SUCCESS = 2;
const STATUS_ERROR = -1;

export default {
  name: 'jwl-programme-applicant',
  components: {
    JwlSubmitRow,
    CommonFormElement,
    CommonIcon,
    JwlApplicantActions,
    sisBoxColumn,
  },
  props: {
    programmeApplicant: Object,
  },
  data () {
    return {
      open: false,
      error: null,
      ecl: this.programmeApplicant.ecl,
      comment: this.programmeApplicant.comment || '',
      submitting: STATUS_NOTHING,
      submittingStatus: STATUS_NOTHING,
    };
  },
  methods: {
    toggleOpen () {
      this.open = !this.open;
    },
    formattedDate (dateString) {
      if (dateString) {
        const date = new Date(dateString);
        return date.toLocaleDateString();
      }
      return '-';
    },
    updateComment () {
      const { commentForm } = this.$refs;
      if (commentForm.reportValidity() && this.submitting !== STATUS_SUBMITTING) {
        this.submitting = STATUS_SUBMITTING;
        const FD = new FormData(this.$refs.commentForm);

        FD.append('performingAction', 'updateComment');
        FD.append('applicantId', this.programmeApplicant.id.toString());

        this.$store.dispatch('postData', {
          url: `programme/update-student/${this.$route.params.programme}`,
          formData: FD,
        }).then(() => {
          this.submitting = STATUS_SUCCESS;
          setTimeout(() => {
            this.submitting = STATUS_NOTHING;
          }, 2500);
        }).catch((e) => {
          this.error = e;
          this.submitting = STATUS_ERROR;
        });
      }
    },
    updateStudent (data) {
      this.$emit('update-student', data);
    },
    updateSyncStatus (newSyncStatus) {
      this.submittingStatus = newSyncStatus;
    },
    getAge (dateString) {
      const today = new Date();
      const birthDate = new Date(dateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age -= 1;
      }
      return age;
    },
  },
  computed: {
    fullName () {
      return `${this.programmeApplicant.firstName} ${this.programmeApplicant.lastName}`;
    },
    openClass () {
      return this.open
        ? 'jwl-programme-applicant__toggle--open'
        : '';
    },
    statusClass () {
      return `jwl-programme-applicant__status--${this.programmeApplicant.status}`;
    },
    statusIcon () {
      switch (this.programmeApplicant.status) {
        case 'declined':
          return 'times';
        case 'active':
          return 'check';
        case 'withdrawn':
          return 'pause';
        case 'completed':
        case 'failed':
          return 'graduation-cap';
        default:
          return 'question';
      }
    },
    statusIconStyle () {
      switch (this.programmeApplicant.status) {
        case 'declined':
        case 'active':
          return 'regular';
        default:
          return 'solid';
      }
    },
    ...mapGetters([
      'currentUser',
    ]),
  },
};
</script>

<style lang="sass">
  @import "../../../styles/jwlProgrammeApplicant"
</style>
