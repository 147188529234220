

























































import { mapGetters } from 'vuex';
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { Programme } from '@/store/interfaces/Programme';

const JwlButton = () => import('@/components/JwlButton.vue');

const STATUS_NOTHING = 0;
const STATUS_SUBMITTING = 1;
const STATUS_SUCCESS = 2;
const STATUS_ERROR = -1;

@Component({
  components: {
    JwlButton,
  },
  computed: mapGetters([
    'currentUser',
  ]),
})
export default class SisProgrammeStatusActions extends Vue {
  @Prop(Object)
  sisProgramme!: Programme;

  syncStatus = STATUS_NOTHING;
  error = null;

  setStatus (status: string): void {
    if (this.syncStatus !== STATUS_SUBMITTING) {
      this.syncStatus = STATUS_SUBMITTING;
      const FD = new FormData();
      FD.append('status', status);

      this.$store.dispatch('postData', {
        url: `programme/set-status/${this.sisProgramme.id.toString()}`,
        formData: FD,
      }).then((data) => {
        this.$store.commit('SET_PROGRAMME', data);
        this.syncStatus = STATUS_SUCCESS;
        setTimeout(() => {
          this.syncStatus = STATUS_NOTHING;
        }, 2500);
      }).catch((e) => {
        this.error = e;
        this.syncStatus = STATUS_ERROR;
      });
    }
  }

  downloadApplicants (): void {
    this.$store.dispatch('getData', `programme/download-applicants/${this.sisProgramme.id.toString()}`)
      .then((data) => {
        window.location.assign(data);
      });
  }

  get pastEndDate (): boolean {
    if (this.sisProgramme.endDate) {
      const now = new Date();
      const end = new Date(this.sisProgramme.endDate);
      return now >= end;
    }
    return false;
  }

  get applicantDownloadAllowedAndPossible (): boolean {
    return this.sisProgramme.status !== 'new';
  }
}
