







































import { mapGetters } from 'vuex';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import User from 'common-modules/src/store/User';
import JwlSubmitRow from '@/components/JwlSubmitRow.vue';
import { ProgrammeBreak } from '@/store/interfaces/Programme';
import SisProgrammeBreak from '@/views/programme/components/SisProgrammeBreak.vue';

const JwlButton = () => import('@/components/JwlButton.vue');
const CommonFormElement = () => import('common-modules/src/components/CommonFormElement.vue');

@Component({
  components: {
    SisProgrammeBreak,
    JwlSubmitRow,
    JwlButton,
    CommonFormElement,
  },
  computed: mapGetters([
    'currentUser',
  ]),
})
export default class SisProgrammeBreaks extends Vue {
  currentUser!: User;

  @Prop(String)
  programmeKey!: string;

  @Prop(Array)
  programmeBreaks!: ProgrammeBreak[];

  addNewOpen = false;

  toggleBreak (): void {
    this.addNewOpen = !this.addNewOpen;
  }

  get addBreakAllowed (): boolean {
    return this.currentUser.role === 'admin';
  }
}
